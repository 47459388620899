import React, { Component } from 'react';
import Utils from '../../Utils';
import { Link } from 'react-router-dom';

class AdminMenu extends Component {
    render() {

        if (!localStorage.bdgp_selected) {
            return (
                <>
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0" />
                    <ul className="navbar-nav pull-right">
                        <li className="nav-item">
                            <Link to='/DatabaseSelect' className="nav-link">Choisir un client</Link>
                        </li>
                    </ul>
                </>
            )
        }

        return (
            <>
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Sociétés
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/societes' className="dropdown-item">Sociétés</Link>
                            <Link to='/sites' className="dropdown-item">Sites</Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Utilisateurs
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/utilisateurs' className="dropdown-item">Utilisateurs</Link>
                            <Link to='/profils/eSellerTeam' className="dropdown-item">Profils eSeller</Link>
                            <Link to='/profils/eDataVoicePrc' className="dropdown-item">Profils eDataVoice (PRC)</Link>
                            <Link to='/utilisateurs/eseller' className="dropdown-item">Gestion des comptes eSeller</Link>
                            <Link to='/utilisateurs/backoffice' className="dropdown-item">Utilisateurs avec accès BackOffice</Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Interfaces
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/interfaces/esellerLead' className="dropdown-item">eSeller Lead</Link>
                            <Link to='/interfaces/planetVO' className="dropdown-item">PlanetVO</Link>
                            <Link to='/interfaces/planetVO2' className="dropdown-item">PlanetVO2</Link>
                            <Link to='/interfaces/bee2Link' className="dropdown-item">Bee2Link</Link>
                            <Link to='#' className="dropdown-item"><del>Ford LDS</del></Link>
                            <Link to='/interfaces/corporateSales' className="dropdown-item">Nissan - Corporate Sales</Link>
                            <Link to='/interfaces/nissanSiebel/kpi' className="dropdown-item">Nissan - Siebel</Link>
                            <Link to='/interfaces/HondaKpi/kpi' className="dropdown-item">Honda - KPI</Link>
                            <Link to='/interfaces/basevn' className="dropdown-item">Base VN</Link>
                            <Link to='/interfaces/opelvn' className="dropdown-item">OpelVN Toolbox</Link>
                            <Link to='/interfaces/emailsms' className="dropdown-item">Email/SMS</Link>
                            <Link to='/interfaces/wltp' className="dropdown-item">WLTP</Link>
                            <Link to='/interfaces/catalogues' className="dropdown-item">Catalogues</Link>
                            <Link to='/interfaces/offreConstructeur' className="dropdown-item">Offre constructeur</Link>
                            <Link to='/interfaces/smartDataForLead' className="dropdown-item">SmartDataForLead</Link>
                            <Link to='/interfaces/wally' className="dropdown-item">Robot Wally</Link>
                            <Link to='/interfaces/dbs' className="dropdown-item">API DBS</Link>
                            <hr />
                            <Link to='/interfaces/renault/csb' className="dropdown-item">Renault - CSB</Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Passerelles
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/passerelles' className="dropdown-item">Passerelles</Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Configuration
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/parametres' className="dropdown-item">GpParametre</Link>
                            <Link to='/listes' className="dropdown-item">Listes</Link>
                            <Link to='/relances' className="dropdown-item">Relances</Link>
                            <Link to='/origines' className="dropdown-item">Origines</Link>
                            <Link to='/lignesFrequentes' className="dropdown-item">Lignes fréquentes</Link>
                            <Link to='/codesOP' className="dropdown-item">Codes OP</Link>
                            <Link to='/BonDeCommande' className="dropdown-item">Bons de commande</Link>
                            <Link to='/docsAdmin' className="dropdown-item">Documents administratifs</Link>
                            <Link to='/cartesDeFidelite' className="dropdown-item">Cartes de fidélité</Link>
                            <Link to='/WSImport' className="dropdown-item">WSImport</Link>
                            {Utils.isActivite(2) ?
                                [
                                    <Link key="1" to='/typesProduction' className="dropdown-item">Types production Agri</Link>,
                                    <Link key="2" to='/typesMaterielAgri' className="dropdown-item">Types materiels Agri</Link>,
                                    <Link key="3" to='/estimationsReprise' className="dropdown-item">Estimations de reprise</Link>,
                                ]
                                : null}
                            <Link to='/statutsVehicules' className="dropdown-item">Statuts véhicules</Link>
                            <Link to='/civilites' className="dropdown-item">Gestion des civilités</Link>
                            <Link to='/objectifsVendeur' className="dropdown-item">Objectifs vendeur (coefficients)</Link>
                            <Link to='/plafondsRemise' className="dropdown-item">Plafonds de remise (PRC)</Link>
                            <Link to='/templatesImpression' className="dropdown-item">Templates d'impression (PRC)</Link>
                            <Link to='/champsFicheClient' className="dropdown-item">Champs Fiche Client (PRC)</Link>

                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Outils
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a href={'https://monitoring.passerelles.selectup.net?server=' + Utils.fromJWT('serverIP') + '&bdgp=' + Utils.fromJWT('BDGp')} target='_blank' className="dropdown-item">Monitoring Passerelles</a>
                            <a href='/nissan/rechercheCodeSite' className="dropdown-item">Nissan - Recherche Code Site / Vendeur</a>
                            <a href='https://skilliance-logs.herokuapp.com/' target='_blank' className="dropdown-item">Skilliance Logs</a>
                            <a href='https://setup.e-seller.selectup.net/' target='_blank' className="dropdown-item">eSeller Setup</a>
                            <a href={'http://interfacecrm.selectup.com/Account/LoginSetup?server=' + Utils.fromJWT('serverName') + '&database=' + Utils.fromJWT('BDGp')} target='_blank' className="dropdown-item"><del>Interfaces</del></a>
                            <a href='/cloture' className="dropdown-item">Clôture des rapports</a>
                            <a href='/logSuppression' className="dropdown-item">LogSuppression</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Global
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/categoriesAgri' className="dropdown-item">Configuration Agri</Link>
                        </div>
                    </li>
                </ul>

                <ul className="navbar-nav pull-right view-selection">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-database" aria-hidden="true"></i> {Utils.fromJWT('BDGp')} ({Utils.fromJWT('serverName')})
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/DatabaseSelect' className="dropdown-item">Changer de client</Link>
                            <a href="#" onClick={this.props.onClickDisconnect} className="dropdown-item">Déconnexion</a>
                        </div>
                    </li>
                </ul>
            </>
        )

    }
}

export default AdminMenu