import React, { Component } from 'react';

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      passerelle: null,
      updated: false,
      fetchInProgress: false,
    }
  }

  componentWillMount() {
    this.fetchPasserelle()
  }

  fetchPasserelle() {
    fetch(localStorage.backoffice_api + '/api/passerelles/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        passerelle: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var passerelle = this.state.passerelle

    if (type === 'checkbox') {
      passerelle[name] = event.target.checked
    }
    else {
      passerelle[name] = value
    }

    this.setState({
      updated: true,
      passerelle: passerelle,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/passerelles/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.passerelle)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        passerelle: null,
        fetchInProgress: false,
      }, () => {
        this.fetchPasserelle()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.passerelle) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Passerelles</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Libelle" className="col-sm-3 col-form-label">Libelle</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Libelle" name="Libelle" value={this.state.passerelle.Libelle} onChange={this.onChange.bind(this)} maxLength="60" required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Commentaire" className="col-sm-3 col-form-label">Commentaire</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Commentaire" name="Commentaire" value={this.state.passerelle.Commentaire} onChange={this.onChange.bind(this)} maxLength="1000" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="DateHRefProchainTransfert" className="col-sm-3 col-form-label">DateHRefProchainTransfert</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="DateHRefProchainTransfert" name="DateHRefProchainTransfert" value={this.state.passerelle.DateHRefProchainTransfert} onChange={this.onChange.bind(this)} />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="EMailsTransfertDMS" className="col-sm-3 col-form-label">EMailsTransfertDMS</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="EMailsTransfertDMS" name="EMailsTransfertDMS" value={this.state.passerelle.EMailsTransfertDMS} onChange={this.onChange.bind(this)} maxLength="1000" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="PubliAutoIntranetVN" checked={this.state.passerelle.PubliAutoIntranetVN} onChange={this.onChange.bind(this)} />
                        &nbsp; Publication Automatique Intranet pour les véhicules neufs
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="PubliAutoInternetVN" checked={this.state.passerelle.PubliAutoInternetVN} onChange={this.onChange.bind(this)} />
                        &nbsp; Publication Automatique Internet pour les véhicules neufs
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="PubliAutoIntranetVD" checked={this.state.passerelle.PubliAutoIntranetVD} onChange={this.onChange.bind(this)} />
                        &nbsp; Publication Automatique Intranet pour les véhicules de démo
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="PubliAutoInternetVD" checked={this.state.passerelle.PubliAutoInternetVD} onChange={this.onChange.bind(this)} />
                        &nbsp; Publication Automatique Internet pour les véhicules de démo
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="PubliAutoIntranetVO" checked={this.state.passerelle.PubliAutoIntranetVO} onChange={this.onChange.bind(this)} />
                        &nbsp; Publication Automatique Intranet pour les véhicules d'occasion
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="PubliAutoInternetVO" checked={this.state.passerelle.PubliAutoInternetVO} onChange={this.onChange.bind(this)} />
                        &nbsp; Publication Automatique Internet pour les véhicules d'occasion
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="TransfertNumOffre" checked={this.state.passerelle.TransfertNumOffre} onChange={this.onChange.bind(this)} />
                        &nbsp; Le n° offre doit être obligatoirement renseigné pour les véhicules vendus (Transfert DMS -> CRM)
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="StockEnTotalite" checked={this.state.passerelle.StockEnTotalite} onChange={this.onChange.bind(this)} />
                        &nbsp; 1 = le stock est envoyé en totalité. 0 = on ne gère pas l'écart de stock
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="CreeRelancesApLivSansCde" checked={this.state.passerelle.CreeRelancesApLivSansCde} onChange={this.onChange.bind(this)} />
                        &nbsp; Créer relances après livraison même si le véhicule ne se trouve pas sur une commande (Gestion des relances sans stock)
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
              </div>
            </div>


            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }
}

export default Form;
