import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv';
import Utils from '../../Utils';

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      utilisateurs: [],
      utilisateursActif: [],
      utilisateursChoix: [],
      choix: 0,
      filterName: '',
      filterProfil: '',
      filterEseller: '',
      profils: [],
      sites: [],
      filterSite: '',
      datasUtilisateursExport: [],
      finishGeneratedDatasUtilisateursExport: false,
      profilEseller: [],
      eSellerLeadActif: false,
    }
  }

  componentWillMount() {
    this.fetchUtilisateurs();
    this.fetchESellerLeadInfos();
    this.fetchUtilisateursSiteExport();
    this.fetchProfilEseller();
  }

  fetchProfilEseller() {

    fetch(localStorage.backoffice_api + '/api/utilisateurs/ProfileSeller', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        if (Utils.isNissan()) {
          lodash.forEach(json, (profil) => {
            if (profil.Libelle === "Chef des ventes") {
              profil.Libelle = "Chef des ventes/d'atelier"
            }
          })
        }
        this.setState({
          profilEseller: json,
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  fetchUtilisateurs() {
    fetch(localStorage.backoffice_api + '/api/utilisateurs', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        var userActif = [];
        var utilisateurs = lodash.sortBy(json, (utilisateur) => {
          return utilisateur.Name.toLowerCase();
        })
        lodash.forEach(utilisateurs, function (user) {
          if (user.Actif == true) {
            userActif.push(user)
          }
        })

        var profils = lodash.map(json, (utilisateur) => {
          return { id: utilisateur.drprofile_id, name: utilisateur.DRPROFILE_DESCR }
        });

        profils = lodash.uniqBy(profils, (profil) => {
          return profil.id;
        });

        profils = lodash.sortBy(profils, (profil) => {
          return profil.name;
        });

        var sites = []
        lodash.map(json, (utilisateur) => {
          return lodash.map(utilisateur.Sites, (site) => {
            sites.push(site)
            return site;
          });
        });
        sites = lodash.uniqBy(sites, (site) => {
          return site.IDSite;
        });
        sites = lodash.sortBy(sites, (site) => {
          return site.Plaque_Libelle + ' - ' + site.Descr;
        });

        this.setState({
          utilisateursChoix: userActif,
          utilisateursActif: userActif,
          utilisateurs: utilisateurs,
          profils: profils,
          sites: sites,
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  fetchUtilisateursSiteExport() {
    fetch(localStorage.backoffice_api + '/api/utilisateursSites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        this.generateDatasUtilisateurs(json);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  fetchESellerLeadInfos() {
    fetch(localStorage.backoffice_api + '/api/interfaces/esellerLead/dashboard', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        this.setState({
          eSellerLeadActif: json.Actif,
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  generateDatasUtilisateurs(utilisateursSites) {

    var datas = [];
    utilisateursSites.map((u, i) => {

      if (i === 0) {
        var keys = Object.keys(u);

        //Si c'est Nissan, on supprime les colonnes Login et Password
        if (Utils.isNissan()) {
          let findIndexLogin = lodash.findIndex(keys, (k) => {
            return k == "LoginWEB"
          })
          keys.splice(findIndexLogin, 1)
          let findIndexPassword = lodash.findIndex(keys, (k) => {
            return k == "Password"
          })
          keys.splice(findIndexPassword, 1)
        }

        keys.push('Device avec notification push');
        datas.push(keys);
      }

      //Si c'est Nissan, on supprime les colonnes Login et Password

      if (Utils.isNissan()) {
        delete u.LoginWEB
        delete u.Password
      }

      var values = Object.values(u);

      values.push(u.IDPeopleDevice ? 'Oui' : 'Non')

      datas.push(values);
    })

    this.setState({
      datasUtilisateursExport: datas,
      finishGeneratedDatasUtilisateursExport: true,
    });
  }

  changeUsers() {
    if (this.state.choix == 0) {
      this.setState({
        utilisateursChoix: this.state.utilisateurs,
        choix: 1
      })
    }
    if (this.state.choix == 1) {
      this.setState({
        utilisateursChoix: this.state.utilisateursActif,
        choix: 0
      })
    }
  }

  getProfil(id) {
    let profils = this.state.profilEseller
    let name;
    profils.forEach(profil => {
      if (profil.IDDRProfileEsellerLead == id) {
        name = profil.Libelle
      }
    })
    return name
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Utilisateurs</h2>

            <br />

            <div>


              {Utils.isAccesClient() ?
                !Utils.isNissan() ?
                  <Link to='/utilisateurs/create' className='btn btn-light'>
                    <i className="fa fa-plus"></i> Ajouter
                  </Link>
                  : null
                :
                [
                  <Link key={1} to='/utilisateurs/create' className='btn btn-light'>
                    <i className="fa fa-plus"></i> Ajouter
                  </Link>,
                  <Link key={2} to='/utilisateurs/importUtilisateurs' className='btn btn-light ml-1'>
                    <i className="fa fa-upload"></i> Importer utilisateurs
                  </Link>
                ]
              }

              {this.state.finishGeneratedDatasUtilisateursExport ?
                <CSVLink data={this.state.datasUtilisateursExport} filename={Utils.fromJWT('BDGp') + '_utilisateurs_sites.csv'} className="btn btn-light ml-1" target="_self" separator={";"}>
                  <i className="fa fa-download" aria-hidden="true"></i> Télécharger utilisateurs et sites
                </CSVLink>
                : null}

              <div className='float-right'>
                <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(event) => this.setState({ filterName: event.target.value })} placeholder='Filtre par nom' />
                  </div>
                </div>

                {this.state.eSellerLeadActif ?
                  <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                    <div className="input-group">
                      <select value={this.state.filterEseller} onChange={(event) => this.setState({ filterEseller: event.target.value })} className="form-control form-control-sm" placeholder='Filtre par profil eSeller Lead'>
                        <option key='0' value=''>Filtre par profil eSeller</option>
                        {this.state.profilEseller.map((profil) => {
                          return <option key={profil.IDDRProfileEsellerLead} value={profil.IDDRProfileEsellerLead}>{profil.Libelle}</option>
                        })}
                      </select>
                    </div>
                  </div>
                  : null}

                <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <select value={this.state.filterProfil} onChange={(event) => this.setState({ filterProfil: event.target.value })} className="form-control form-control-sm" placeholder='Filtre par profil'>
                      <option key='0' value=''>Filtre par profil</option>
                      {this.state.profils.map((profil) => {
                        if (!profil.id)
                          return null;
                        return <option key={profil.id} value={profil.id}>{profil.name}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-inline float-left" style={{ marginTop: '2px', }}>
                  <div className="input-group">
                    <select value={this.state.filterSite} onChange={(event) => this.setState({ filterSite: event.target.value })} className="form-control form-control-sm" placeholder='Filtre par site'>
                      <option key='0' value=''>Filtre par site</option>
                      {this.state.sites.map((site) => {
                        return <option key={site.IDSite} value={site.IDSite}>{site.Plaque_Libelle} - {site.Descr}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th>Téléphone</th>
                  <th>Email</th>
                  <th>Profil d'utilisateur</th>
                  {this.state.eSellerLeadActif ? <th>Profil eSeller-LEAD</th> : null}
                  {//TODO: Il faut pour savoir si eDataVoice est activé
                  this.state.eSellerLeadActif ? <th>Profil eDataVoice (PRC)</th> : null}
                  <th>Actif <input type="checkbox" id="actif" defaultChecked="false" onChange={this.changeUsers ? this.changeUsers.bind(this) : null} ></input></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.utilisateursChoix.map((utilisateur) => {

                  if (Utils.isAccesClient()) {
                    if (utilisateur.DRPROFILE_DESCR === "Chef de région Nissan") {
                      return null
                    }
                  }

                  if (this.state.filterName) {
                    if (utilisateur.Name.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
                      return null;
                    }
                  }

                  if (this.state.filterProfil) {
                    if (parseInt(this.state.filterProfil, 10) !== parseInt(utilisateur.drprofile_id, 10)) {
                      return null;
                    }
                  }

                  if (this.state.filterEseller) {
                    if (parseInt(this.state.filterEseller, 10) !== parseInt(utilisateur.IDDRProfileEsellerLead, 10)) {
                      return null;
                    }
                  }

                  if (this.state.filterSite) {
                    var sitesUsersFind = lodash.findIndex(utilisateur.Sites, (site) => {
                      return parseInt(site.IDSite, 10) === parseInt(this.state.filterSite, 10)
                    })
                    if (sitesUsersFind < 0) {
                      return null;
                    }
                  }

                  return (<tr key={utilisateur.IDPeople}>
                    <td>{utilisateur.IDPeople}</td>
                    <td>{utilisateur.Name}</td>
                    <td>{utilisateur.PhoneNumber}</td>
                    <td>{utilisateur.EMail}</td>
                    <td>{utilisateur.DRPROFILE_DESCR}</td>
                    {this.state.eSellerLeadActif ? <td>{this.getProfil(utilisateur.IDDRProfileEsellerLead)}</td> : null}
                    {this.state.eSellerLeadActif ? <td>{utilisateur.Profil_Libelle}</td> : null}
                    <td>{utilisateur.Actif ? 'Oui' : 'Non'}</td>
                    <td>{utilisateur.Devices ? <i className="fa fa-mobile" aria-hidden="true" style={{ fontSize: '29px' }}></i> : null}</td>
                    <td className="text-right">
                      <Link to={'/utilisateurs/' + utilisateur.IDPeople} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                      &nbsp;
                      {!Utils.isAccesClient() ?
                        <div className="dropdown" style={{ display: 'inline-block' }}>
                          <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                          </button>
                          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                            <Link className='dropdown-item' target='_blank' to={'/utilisateurs/' + utilisateur.IDPeople + '/connect/team'}>Se connecter sur eSeller Team</Link>
                            <Link className='dropdown-item' target='_blank' to={'/utilisateurs/' + utilisateur.IDPeople + '/connect/lead'}>Se connecter sur eSeller Lead</Link>
                            <Link className='dropdown-item' target='_blank' to={'/utilisateurs/' + utilisateur.IDPeople + '/connect/reporting'}>Se connecter sur Reporting eSeller</Link>
                            {/* <Link className='dropdown-item' target='_blank' to={'/utilisateurs/' + utilisateur.IDPeople + '/connect/eDataVoice'}>Se connecter sur Poste Relation Client</Link> */}
                          </div>
                        </div>
                        : null}
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
