import React from 'react'
import ReactDOM from 'react-dom'

import {
  Router,
  Route,
  Switch
} from 'react-router-dom'

import 'react-select/dist/react-select.css';

import ScrollButton from './components/ScrollButton'

import Dashboard from './components/Dashboard/Dashboard'
import Header from './components/Header/Header'
import NotFound from './NotFound'

import Login from './components/Login/Login'
import DatabaseSelect from './components/DatabaseSelect'

import InterfaceEsellerLead from './components/Interfaces/EsellerLead/Index'
import InterfaceEsellerLeadSynthese from './components/Interfaces/EsellerLead/Synthese'

import InterfaceCorporateSales from './components/Interfaces/CorporateSales/Index'

import InterfaceNissanSiebelKpi from './components/Interfaces/NissanSiebel/Kpi'

import InterfaceHondaKpi from './components/Interfaces/HondaKpi/Kpi'

import InterfaceBaseVN from './components/Interfaces/BaseVN/Index'

import InterfacePlanetVO2 from './components/Interfaces/PlanetVO2/Index'

import InterfaceOpelVN from './components/Interfaces/OpelVN/Index'

import InterfaceSmartDataForLead from './components/Interfaces/SmartDataForLead/Index'
import InterfaceWally from './components/Interfaces/Wally/Index'
import InterfaceDBS from './components/Interfaces/DBS/Index'
import InterfaceRenaultCSB from './components/Interfaces/Renault/CSB'

import Catalogues from './components/Interfaces/Catalogues/Index.js'
import OffreConstructeur from './components/Interfaces/OffreConstructeur/Index'

import InterfacePlanetVO from './components/Interfaces/PlanetVO/Index'
import InterfacePlanetVOUtilisateur from './components/Interfaces/PlanetVO/Utilisateur'

import InterfaceBee2Link from './components/Interfaces/Bee2Link/Form'

import InterfaceEmailSMS from './components/Interfaces/EmailSMS/Form'

import WLTP from './components/Interfaces/WLTP/Index';

import UtilisateurEseller from './components/Utilisateurs/Eseller'
import UtilisateursBackOffice from './components/Utilisateurs/BackOffice'
import Utilisateurs from './components/Utilisateurs/List'
import UtilisateurForm from './components/Utilisateurs/Form'
import UtilisateurConnect from './components/Utilisateurs/UtilisateurConnect'
import UtilisateursImport from './components/Utilisateurs/ImportUtilisateurs'

import EsellerTeamProfils from './components/Profils/EsellerTeam/List.js'
import EsellerTeamProfilForm from './components/Profils/EsellerTeam/Form.js'
import EsellerTeamProfilDuplicate from './components/Profils/EsellerTeam/Duplicate.js'
import EDataVoicePrcProfils from './components/Profils/EDataVoicePrc/List.js'
import EDataVoicePrcProfilForm from './components/Profils/EDataVoicePrc/Form.js'

import BonDeCommande from './components/BonDeCommande/List'
import BonDeCommandeForm from './components/BonDeCommande/Form'

import Passerelles from './components/Passerelles/List'
import PasserelleHistory from './components/Passerelles/History'
import PasserelleHistoryErrors from './components/Passerelles/HistoryErrors'
import PasserelleForm from './components/Passerelles/Form'
import PasserelleColumns from './components/Passerelles/Columns'

import Societes from './components/Societes/List'
import SocieteForm from './components/Societes/Form'

import Sites from './components/Sites/List'
import SiteForm from './components/Sites/Form'

import Relances from './components/Relances/List'
import RelancesForm from './components/Relances/Form'

import HolidayTypes from './components/HolidayTypes/List'
import HolidayTypesForm from './components/HolidayTypes/Form'

import SituationFamiliale from './components/SituationFamiliale/List'
import SituationFamilialeForm from './components/SituationFamiliale/Form'

import MoyenPaiement from './components/MoyenPaiement/List'
import MoyenPaiementForm from './components/MoyenPaiement/Form'

import Origines from './components/Origines/List'

import OriginesContact from './components/OriginesContact/List'
import OriginesContactForm from './components/OriginesContact/Form'
import OriginesContactTransfert from './components/OriginesContact/Transfert'

import GestionCivilites from './components/Civilites/List'
import GestionCivilitesForm from './components/Civilites/Form'

import WSImport from './components/WSImport/List'
import WSImportForm from './components/WSImport/Form'

import LignesFrequentes from './components/LignesFrequentes/List'
import LignesFrequentesForm from './components/LignesFrequentes/Form'
import CodesOP from './components/CodesOP/List'
import CodesOPForm from './components/CodesOP/Form'
import FonctionsList from './components/Fonctions/List'
import FonctionsForm from './components/Fonctions/Form'

import CartesDeFideliteList from './components/CartesDeFidelite/List'
import CartesDeFideliteForm from './components/CartesDeFidelite/Form'
import CartesDeFideliteImportClients from './components/CartesDeFidelite/ImportClients'

import DocsAdminList from './components/DocsAdmin/List';
import DocsAdminForm from './components/DocsAdmin/Form';

import GpParametresForm from './components/GpParametres/Form'

import CategoriesAgri from './components/CategoriesAgri/Index'
import TypesProduction from './components/TypesProduction/Index'
import TypesMaterielAgri from './components/TypesMaterielAgri/Index'
import TypesMaterielAgriChamps from './components/TypesMaterielAgri/Champs'
import EstimationsReprise from './components/EstimationsReprise/Index'

import createBrowserHistory from 'history/createBrowserHistory'

import Utils from './Utils';
import Cloture from './components/Cloture/Cloture'
import LogSuppression from './components/LogSuppression/LogSuppression'
import OffreReglement from './components/OffreReglement/List'
import OffreReglementForm from './components/OffreReglement/Form'
import StatutsVehicules from './components/StatutsVehicules/List'
import NissanRechercheCodeSite from './components/NissanRechercheCodeSite.js'
import ObjectifsVendeur from './components/ObjectifsVendeur/Form';

import WorkflowsLeads from './components/WorkflowsLeads/Index'
import TemplatesImpression from './components/TemplatesImpression/Index'
import ChampsFicheClient from './components/ChampsFicheClient/Index'

import LibellesRemise from './components/LibellesRemise/List'
import LibellesRemiseForm from './components/LibellesRemise/Form'
import PlafondsRemise from './components/PlafondsRemise/Index'

import RaisonClotureLead from './components/RaisonClotureLead/List'
import RaisonClotureLeadForm from './components/RaisonClotureLead/Form'

import Listes from './components/Listes/Index.js'
import CategorieClient from './components/CategorieClient/List'
import CategorieClientForm from './components/CategorieClient/Form'

import StatutsJuridiques from './components/StatutsJuridiques/List'
import StatutsJuridiquesForm from './components/StatutsJuridiques/Form'

const customHistory = createBrowserHistory()

var routes = []

if(localStorage.backoffice_api && localStorage.JWT) {
  const commonRoutes = [
    { path: "/utilisateurs", component: Utilisateurs, exact: true },
    { path: "/societes/create", component: SocieteForm },
    { path: "/societes/:id", component: SocieteForm },
    { path: "/societes", component: Societes },
    { path: "/sites/create", component: SiteForm },
    { path: "/sites/:id", component: SiteForm },
    { path: "/sites", component: Sites },
    { path: "/lignesFrequentes/create", component: LignesFrequentesForm },
    { path: "/lignesFrequentes/:id", component: LignesFrequentesForm },
    { path: "/lignesFrequentes", component: LignesFrequentes },
    { path: "/codesOP/create", component: CodesOPForm },
    { path: "/codesOP/:id", component: CodesOPForm },
    { path: "/codesOP", component: CodesOP },
    { path: "/originesContact/create", component: OriginesContactForm },
    { path: "/originesContact/:id", component: OriginesContactForm },
    { path: "/originesContact/transfert", component: OriginesContactTransfert },
    { path: "/originesContact", component: OriginesContact },
    { path: "/cartesDeFidelite/create", component: CartesDeFideliteForm },
    { path: "/cartesDeFidelite/importClients", component: CartesDeFideliteImportClients },
    { path: "/cartesDeFidelite/:id", component: CartesDeFideliteForm },
    { path: "/cartesDeFidelite", component: CartesDeFideliteList },
    { path: "/fonctions/create", component: FonctionsForm },
    { path: "/fonctions/:id", component: FonctionsForm },
    { path: "/fonctions", component: FonctionsList },
    { path: "/table/SITFAM/create", component: SituationFamilialeForm },
    { path: "/table/SITFAM/:id", component: SituationFamilialeForm },
    { path: "/table/SITFAM", component: SituationFamiliale },
    { path: "/relances/create", component: RelancesForm },
    { path: "/relances/:id", component: RelancesForm },
    { path: "/relances", component: Relances },
    { path: "/objectifsVendeur", component: ObjectifsVendeur },
    { path: "/libellesRemise/create", component: LibellesRemiseForm },
    { path: "/libellesRemise/:id", component: LibellesRemiseForm },
    { path: "/libellesRemise", component: LibellesRemise },
    { path: "/plafondsRemise", component: PlafondsRemise },
    { path: "/workflowsLeads", component: WorkflowsLeads }
  ];

  const clientAccessSpecificRoutes = [
    { path: "/", component: Utilisateurs, exact: true },
    { path: "/dashboard", component: Utilisateurs },
    { path: "/utilisateurs/eseller", component: UtilisateurEseller, condition: !Utils.isNissan() },
    { path: "/utilisateurs/create", component: UtilisateurForm, condition: !Utils.isNissan() },
    { path: "/utilisateurs/:id", component: UtilisateurForm, condition: !Utils.isNissan() },
    { path: "/profils/eSellerTeam/create", component: EsellerTeamProfilDuplicate, condition: !Utils.isNissan() },
    { path: "/profils/eSellerTeam/:id", component: EsellerTeamProfilForm, condition: !Utils.isNissan() },
    { path: "/profils/eSellerTeam", component: EsellerTeamProfils, condition: !Utils.isNissan() },
    { path: "/interfaces/wally", component: InterfaceWally },
    { path: "/passerelles/:id/history/:IDPasserelleDMSHisto/errors", component: PasserelleHistoryErrors },
    { path: "/passerelles/:id/history", component: PasserelleHistory },
    { path: "/passerelles", component: Passerelles }
  ];

  const adminSpecificRoutes = [
    { path: "/", component: Dashboard, exact: true },
    { path: "/dashboard", component: Dashboard },
    { path: "/DatabaseSelect", component: DatabaseSelect },
    { path: "/interfaces/esellerLead/synthese", component: InterfaceEsellerLeadSynthese },
    { path: "/interfaces/esellerLead", component: InterfaceEsellerLead },
    { path: "/interfaces/corporateSales", component: InterfaceCorporateSales },
    { path: "/interfaces/basevn", component: InterfaceBaseVN },
    { path: "/interfaces/opelvn", component: InterfaceOpelVN },
    { path: "/interfaces/planetVO2", component: InterfacePlanetVO2 },
    { path: "/interfaces/planetVO/utilisateurs/:id", component: InterfacePlanetVOUtilisateur },
    { path: "/interfaces/planetVO", component: InterfacePlanetVO },
    { path: "/interfaces/bee2Link", component: InterfaceBee2Link },
    { path: "/interfaces/catalogues", component: Catalogues },
    { path: "/interfaces/offreConstructeur", component: OffreConstructeur },
    { path: "/interfaces/nissanSiebel/kpi", component: InterfaceNissanSiebelKpi },
    { path: "/interfaces/HondaKpi/kpi", component: InterfaceHondaKpi },
    { path: "/interfaces/wltp", component: WLTP },
    { path: "/interfaces/emailsms", component: InterfaceEmailSMS },
    { path: "/interfaces/smartDataForLead", component: InterfaceSmartDataForLead },
    { path: "/interfaces/dbs", component: InterfaceDBS },
    { path: "/interfaces/wally", component: InterfaceWally },
    { path: "/interfaces/renault/csb", component: InterfaceRenaultCSB },
    { path: "/utilisateurs/eseller", component: UtilisateurEseller },
    { path: "/utilisateurs/backoffice", component: UtilisateursBackOffice },
    { path: "/utilisateurs/create", component: UtilisateurForm },
    { path: "/utilisateurs/importUtilisateurs", component: UtilisateursImport },
    { path: "/utilisateurs/:id/connect/:product", component: UtilisateurConnect },
    { path: "/utilisateurs/:id", component: UtilisateurForm },
    { path: "/profils/eSellerTeam/create", component: EsellerTeamProfilDuplicate },
    { path: "/profils/eSellerTeam/:id", component: EsellerTeamProfilForm },
    { path: "/profils/eSellerTeam", component: EsellerTeamProfils },
    { path: "/profils/eDataVoicePrc/:id", component: EDataVoicePrcProfilForm },
    { path: "/profils/eDataVoicePrc", component: EDataVoicePrcProfils },
    { path: "/passerelles/:id/columns", component: PasserelleColumns },
    { path: "/passerelles/:id/history/:IDPasserelleDMSHisto/errors", component: PasserelleHistoryErrors },
    { path: "/passerelles/:id/history", component: PasserelleHistory },
    { path: "/passerelles/:id", component: PasserelleForm },
    { path: "/passerelles", component: Passerelles },
    { path: "/BonDeCommande/create", component: BonDeCommandeForm },
    { path: "/BonDeCommande/:id", component: BonDeCommandeForm },
    { path: "/BonDeCommande", component: BonDeCommande },
    { path: "/holidayTypes/create", component: HolidayTypesForm },
    { path: "/holidayTypes/:id", component: HolidayTypesForm },
    { path: "/holidayTypes", component: HolidayTypes },
    { path: "/table/MoyenPaiement/create", component: MoyenPaiementForm },
    { path: "/table/MoyenPaiement/:id", component: MoyenPaiementForm },
    { path: "/table/MoyenPaiement", component: MoyenPaiement },
    { path: "/civilites/create", component: GestionCivilitesForm },
    { path: "/civilites/:id", component: GestionCivilitesForm },
    { path: "/civilites", component: GestionCivilites },
    { path: "/origines", component: Origines },
    { path: "/wsimport/create", component: WSImportForm },
    { path: "/wsimport", component: WSImport },
    { path: "/docsAdmin/create", component: DocsAdminForm },
    { path: "/docsAdmin/:id", component: DocsAdminForm },
    { path: "/docsAdmin", component: DocsAdminList },
    { path: "/parametres", component: GpParametresForm },
    { path: "/cloture", component: Cloture },
    { path: "/logSuppression", component: LogSuppression },
    { path: "/offrereglement/create", component: OffreReglementForm },
    { path: "/offrereglement/:id", component: OffreReglementForm },
    { path: "/offrereglement", component: OffreReglement },
    { path: "/statutsVehicules", component: StatutsVehicules },
    { path: "/nissan/rechercheCodeSite", component: NissanRechercheCodeSite },
    { path: "/categoriesAgri", component: CategoriesAgri },
    { path: "/typesProduction", component: TypesProduction },
    { path: "/typesMaterielAgri/:IDTypeMaterialAgri/champs", component: TypesMaterielAgriChamps },
    { path: "/typesMaterielAgri", component: TypesMaterielAgri },
    { path: "/estimationsReprise", component: EstimationsReprise },
    { path: "/templatesImpression", component: TemplatesImpression },
    { path: "/champsFicheClient", component: ChampsFicheClient },
    { path: "/table/RaisonClotureLead/create", component: RaisonClotureLeadForm },
    { path: "/table/RaisonClotureLead/:id", component: RaisonClotureLeadForm },
    { path: "/table/RaisonClotureLead", component: RaisonClotureLead },
    { path: "/listes", component: Listes },
    { path: "/table/CategorieClient/create", component: CategorieClientForm },
    { path: "/table/CategorieClient/:id", component: CategorieClientForm },
    { path: "/table/CategorieClient", component: CategorieClient },
    { path: "/statutsJuridiques/create", component: StatutsJuridiquesForm },
    { path: "/statutsJuridiques/:id", component: StatutsJuridiquesForm },
    { path: "/statutsJuridiques", component: StatutsJuridiques }
  ];

  routes = Utils.isAccesClient() ? [...commonRoutes, ...clientAccessSpecificRoutes] : [...commonRoutes, ...adminSpecificRoutes];
}


ReactDOM.render((
  <Router history={customHistory}>

    {localStorage.backoffice_api && localStorage.JWT ?
      <div>
        <Header />
        <Switch>
          {routes.filter(route => route.condition !== false).map((route, index) => (
            <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
          ))}
          <Route component={NotFound} />
        </Switch>

        <ScrollButton scrollStepInPx="100" delayInMs="13" />
      </div>
      :
      <Login />
    }
  </Router>
), document.getElementById('root'))
