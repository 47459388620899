import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Select from 'react-select';
import Utils from '../Utils';
var lodash = require('lodash')

class DatabaseSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      servers: [],
      databases_servers:[],
      fetchInProgress: false,
      database_server:''
    }
  }

  componentWillMount() {
    if(Utils.isAccesClient()){
      window.location.href = '/';
      return;
    }
    localStorage.backoffice_api = process.env.REACT_APP_BACKOFFICE_API
    delete localStorage.bdgp_selected;
    this.fetchServers();
  }

  fetchServers() {
    fetch(process.env.REACT_APP_BACKOFFICE_API + '/api/servers', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        servers: json,
      }, () => {
        this.fetchDatabase_servers();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchDatabase_servers() {
    var res = [];
    
    this.state.servers.forEach(s => {
      
      localStorage.backoffice_api = process.env.REACT_APP_BACKOFFICE_API
      if (s.isHonda) {
        localStorage.backoffice_api = process.env.REACT_APP_BACKOFFICE_API_HONDA
      }
      fetch(localStorage.backoffice_api + '/api/databases', {
        method: 'GET',
        headers: {
          server: s.ip,
          authorization: localStorage.JWT,
        }
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        json.forEach(db => {
          res.push({
            database: db,
            server: s
          });
        });
        this.setState({
          databases_servers: lodash.sortBy(res, (d) => {
            return d.database.name
          }),
        }); 
      })
      .catch((error) => {
        console.log(error)
      });
    });   
  }

  onChangeDatabaseServer(event) {
    this.setState({
      database_server: event ? event.value : null,
    });
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({
      fetchInProgress: true,
    })

    var res = lodash.filter(this.state.databases_servers, (db_serv) => {
      var ip = this.state.database_server.split("/")[1];
      var name = this.state.database_server.split("/")[0];
      if(db_serv.server.ip == ip && db_serv.database.name == name){
        return db_serv;
      }
    })

    localStorage.backoffice_api = process.env.REACT_APP_BACKOFFICE_API
    if (res[0].server.isHonda) {
      localStorage.backoffice_api = process.env.REACT_APP_BACKOFFICE_API_HONDA
    }

    if (!res.length) {
      this.setState({
        fetchInProgress: false,
      });
      return;
    }

    fetch(localStorage.backoffice_api + '/api/adminSelectDatabase', {
      method: 'POST',
      headers: {
        server: res[0].server.ip,
        bdgp: res[0].database.name,
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      localStorage.JWT = 'JWT ' + json.token;
      localStorage.bdgp_selected = 1;
      localStorage.activites = json.activites;
      this.props.history.push('/dashboard')
    })
    .catch((error) => {
      console.log(error)
    }); 

  }

  render() {

    return (
      <div className='container' style={{ marginTop: '50px' }}>
        <div className='row justify-content-center'>
          <div className='col-lg-6 col-md-6 text-center'>
            <h3 style={{ marginBottom: '20px' }}>Sélection d'un client</h3>

            <form className="form-inline" onSubmit={this.handleSubmit.bind(this)}>

            {this.state.databases_servers != [] ?
                <div className="col-sm-12 text-left" style={{ marginTop: '15px' }}>
                  <Select multi={false} name="database" value={this.state.database_server} options={this.state.databases_servers.map((item) => {
                    return {value:  item.database.name+'/'+item.server.ip, label: item.database.name + ' ('+ item.server.name + ' - ' + item.server.ip + ')' } ;
                  })} searchable={true} placeholder='Choisissez une base de données' onChange={this.onChangeDatabaseServer.bind(this)} required={true} />
                </div>
              : null}

              {this.state.database_server ?
                <div className='text-center' style={{ width: '100%', }}>
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary" style={{ marginTop: '15px', width: '80%' }}>
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Valider
                  </button>
                </div>
              : null}

            </form>

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DatabaseSelect);
